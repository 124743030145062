interface Props {
  firstName: string | undefined
  lastName: string | undefined
}

export const getFullName = ({ firstName, lastName }: Props) => {
  if (firstName && lastName) {
    return [firstName, lastName].join(' ')
  }

  return firstName || lastName || ''
}
