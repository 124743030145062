import { EnumSponsorshipVoucherStatus } from '@blank/api'
import { useAuthenticatedUserCorporateQuery } from 'modules/auth/queries/useAuthenticatedUserCorporateQuery'
import { useCorporateSponsorshipQuery } from '../queries/useCorporateSponsorshipQuery'

export const useCorporateSponsorship = () => {
  const {
    data: { corporate },
  } = useAuthenticatedUserCorporateQuery()

  const {
    data: sponsorship,
    isLoading: isCorporateSponsorshipLoading,
    isError: isCorporateSponsorshipError,
    refetch: refetchCorporateSponsorship,
  } = useCorporateSponsorshipQuery(corporate?.id)

  const activeVoucher = sponsorship?.vouchers.find(
    ({ status }) => status === EnumSponsorshipVoucherStatus.ACTIVE
  )

  return {
    isCorporateSponsorshipLoading,
    isCorporateSponsorshipError,
    refetchCorporateSponsorship,
    isEligibleForSponsorship: !!sponsorship?.isEligible,
    activeVoucher,
  }
}
