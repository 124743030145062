import { CorporateSponsorshipApi } from '@blank/api'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import { EnumCorporateQueryKeys } from 'modules/corporate/queries/keys'
import { fetchCorporateSponsorship } from '../api/fetchCorporateSponsorship'

export const useCorporateSponsorshipQuery = (
  corporateId: string | undefined,
  queryOptions?: QueryOptions<CorporateSponsorshipApi, Error>
) => {
  return useQueryWithAuth<CorporateSponsorshipApi, Error>({
    queryKey: [EnumCorporateQueryKeys.CORPORATES_SPONSORSHIP],
    queryFn: async (_context, accessToken) => {
      if (!corporateId) {
        throw new Error(
          'useCorporateSponsorshipQuery: Missing parameter corporateId'
        )
      }

      return fetchCorporateSponsorship({ accessToken, corporateId })
    },
    ...queryOptions,
  })
}
