import { Image, ImageProps } from '../Image/Image'

export type ResponsiveImageProps = Omit<ImageProps, 'width' | 'height'>

export const ResponsiveImage = ({ ...props }: ResponsiveImageProps) => {
  return (
    <Image
      {...props}
      // Arbitrary values are required for width and height to handle reponsiveness with className
      width="0"
      height="0"
    />
  )
}
