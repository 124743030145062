import { mergeClasses } from '@blank/utilities'

interface Props {
  className?: string
  withBorder?: boolean
}

export const NotificationDot = ({ className, withBorder }: Props) => (
  <div
    className={mergeClasses(
      'rounded-full  bg-primary',
      {
        'h-3 w-3 border-2 border-white': withBorder,
        'h-2 w-2': !withBorder,
      },
      className
    )}
  />
)
