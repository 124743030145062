import { mergeClasses } from '@blank/utilities'
import { useLogoutFlow } from 'modules/auth/flows/useLogoutFlow'
import { useDropdownMenu } from 'modules/common/hooks/useDropdownMenu'
import { useFeatureFlipping } from 'modules/common/hooks/useFeatureFlipping'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { getFullName } from 'modules/common/lib/helpers/names/getFullName'
import { EnumRoutes } from 'modules/common/routes'
import { useCorporateSponsorship } from 'modules/corporate/hooks/useCorporateSponsorship'
import { useCorporateUserName } from 'modules/corporate/hooks/useCorporateUserName'
import { useRef } from 'react'
import packageJson from '../../../../../package.json'
import { PersonBadge } from '../PersonBadge/PersonBadge'
import {
  IconExpandMore,
  IconGift,
  IconInformationSquare,
  IconLogOut,
} from '../_icons/icons'
import { Button } from '../_ui/Button/Button'
import { DropdownMenu } from '../_ui/DropdownMenu/DropdownMenu'
import { DropdownMenuItem } from '../_ui/DropdownMenu/DropdownMenuItem'
import { NotificationDot } from '../_ui/NotificationDot/NotificationDot'

export const HeaderMenu = () => {
  const { t } = useTranslation('common-components')
  const ref = useRef(null)

  const { firstName, lastName } = useCorporateUserName()
  const { logout, loading } = useLogoutFlow()
  const userName = getFullName({ firstName, lastName })
  const { isEligibleForSponsorship } = useCorporateSponsorship()
  const { canDisplayVersionNumber } = useFeatureFlipping()

  const {
    registerDropdownMenu,
    registerDropdownButton,
    registerDropdownMenuItem,
    isOpen,
  } = useDropdownMenu({ triggerOffset: 4 })

  return (
    <div className="relative flex items-center gap-2" ref={ref}>
      <Button
        {...registerDropdownButton()}
        variant="none"
        className="flex flex-row items-center"
        data-testid="header-menu-account-dropdown"
      >
        {firstName && (
          <PersonBadge
            name={firstName}
            className="mr-2"
            withNotificationDot={isEligibleForSponsorship}
          />
        )}
        <p className="max-w-[64px] overflow-hidden text-ellipsis whitespace-nowrap align-middle font-bold uppercase md:max-w-none">
          {userName || t('headerMenu.placeholder')}
        </p>
        <IconExpandMore
          className={mergeClasses('transition-transform', {
            'rotate-180 transform': isOpen,
          })}
        />
      </Button>
      <DropdownMenu className="rounded-t-none" {...registerDropdownMenu()}>
        {isEligibleForSponsorship && (
          <DropdownMenuItem
            variant="tertiary"
            color="default"
            icon={<IconGift className="shrink-0" />}
            data-testid="header-menu-sponsorship-button"
            buttonContentClassName="grow"
            href={EnumRoutes.SETTINGS_SPONSORSHIP}
            {...registerDropdownMenuItem()}
          >
            <div className="flex w-full items-center justify-between">
              {t('headerMenu.sponsorship')}
              <NotificationDot />
            </div>
          </DropdownMenuItem>
        )}
        {canDisplayVersionNumber && (
          <DropdownMenuItem
            variant="tertiary"
            color="default"
            icon={<IconInformationSquare />}
            {...registerDropdownMenuItem()}
          >
            {packageJson.version}
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          variant="tertiary"
          color="danger"
          isLoading={loading}
          icon={<IconLogOut />}
          data-testid="header-menu-logout-button"
          className="!justify-start"
          {...registerDropdownMenuItem({ onClick: logout })}
        >
          {t('headerMenu.logout')}
        </DropdownMenuItem>
      </DropdownMenu>
    </div>
  )
}
