import { mergeClasses } from '@blank/utilities'
import { Badge } from 'modules/common/components/_ui/Badge/Badge'
import { PropsWithChildren } from 'react'

interface Props {
  name: string | undefined
  withNotificationDot?: boolean
  className?: string
}

export const PersonBadge = ({
  name,
  withNotificationDot,
  className,
}: PropsWithChildren<Props>) => {
  const userInitials = name ? name[0] : ''

  return (
    <Badge
      withNotificationDot={withNotificationDot}
      className={mergeClasses(
        'bg-info-600 font-bold uppercase text-white',
        className
      )}
    >
      {userInitials}
    </Badge>
  )
}
