import { mergeClasses } from '@blank/utilities'
import { PropsWithChildren } from 'react'
import { NotificationDot } from '../NotificationDot/NotificationDot'
import { Skeleton } from '../Skeletons/Skeleton/Skeleton'

interface Props {
  className?: string
  withNotificationDot?: boolean
}

export const Badge = ({
  children,
  withNotificationDot,
  className,
}: PropsWithChildren<Props>) => (
  <div
    className={mergeClasses(
      'relative flex h-11 w-11 shrink-0 items-center justify-center rounded-full border-2 border-info-100',
      className
    )}
  >
    {withNotificationDot && (
      <NotificationDot className="absolute -right-0.5 top-0.5" withBorder />
    )}
    {children}
  </div>
)

export const BadgeSkeleton = ({ className }: Props) => {
  return (
    <Skeleton
      className={mergeClasses(
        'h-11 w-11 rounded-full ring-2 ring-white',
        className
      )}
    />
  )
}
