import { useAuthenticatedUserCorporateQuery } from 'modules/auth/queries/useAuthenticatedUserCorporateQuery'

export const useCorporateUserName = () => {
  const { data } = useAuthenticatedUserCorporateQuery()

  return {
    firstName: data?.corporate?.requester?.firstName,
    lastName: data?.corporate?.requester?.lastName,
  }
}
