import { useAppContextState } from 'modules/common/contexts/AppContext'
import { EnumRoutes } from 'modules/common/routes'
import { ReactNode } from 'react'
import { Link } from '../Link/Link'
import { ResponsiveImage } from '../ResponsiveImage/ResponsiveImage'
import { HeaderMenu } from './HeaderMenu'

interface Props {
  logoPath?: string
  redirectURL?: string
  rightItem?: ReactNode
  withUser?: boolean
}

export const Header = ({
  logoPath = '/assets/svg/logo.svg',
  redirectURL,
  rightItem,
  withUser = false,
}: Props) => {
  const { brand } = useAppContextState()

  const defaultRedirectUrl = withUser
    ? EnumRoutes.HOME
    : (process.env.NEXT_PUBLIC_MARKETING_SITE_ROOT as string)

  return (
    <div className="sticky top-0 z-30 flex h-header w-full flex-row items-center justify-between border-b border-info-100 bg-white px-5">
      <Link href={redirectURL || defaultRedirectUrl}>
        <ResponsiveImage
          src={logoPath}
          alt={`logo-${brand}`}
          className="h-5 w-auto"
        />
      </Link>
      <div className="flex h-8 items-stretch justify-center gap-5 divide-x divide-info-300 [&>*]:flex [&>*]:items-center [&>*]:pl-5">
        {rightItem && <div>{rightItem}</div>}
        {withUser && (
          <div>
            <HeaderMenu />
          </div>
        )}
      </div>
    </div>
  )
}
