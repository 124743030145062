import { mergeClasses } from '@blank/utilities'
import { Trans } from 'next-i18next'
import { PropsWithChildren } from 'react'
import {
  IconCheckRounded,
  IconCross,
  IconCrossRounded,
  IconDanger,
  IconInformationSquare,
} from '../_icons/icons'
import { Button } from '../_ui/Button/Button'

const ICONS = {
  info: <IconInformationSquare />,
  warning: <IconDanger />,
  error: <IconCrossRounded />,
  success: <IconCheckRounded />,
}

export const TOAST_MESSAGE_TYPES = [
  'info',
  'warning',
  'error',
  'success',
] as const
export type ToastMessageType = (typeof TOAST_MESSAGE_TYPES)[number]

interface Props {
  type: ToastMessageType
  title?: string
  description: string
  onClose?: () => void
}

export const ToastMessage = ({
  type,
  title,
  description,
  onClose,
}: PropsWithChildren<Props>) => {
  const messageIcon = ICONS[type]

  return (
    <div
      className={mergeClasses(
        'relative w-96 overflow-hidden rounded-lg border bg-white text-info-600 shadow-md',
        {
          'border-primary': type === 'info',
          'border-warning': type === 'warning',
          'border-danger': type === 'error',
          'border-success': type === 'success',
        }
      )}
    >
      <div
        className={mergeClasses(
          'absolute h-1 w-full origin-left animate-[fill_linear_5s_1_forwards]',
          {
            'bg-primary': type === 'info',
            'bg-warning': type === 'warning',
            'bg-danger': type === 'error',
            'bg-success': type === 'success',
          }
        )}
      ></div>
      <div className="flex items-center space-x-2 px-2 py-3">
        {messageIcon && (
          <span
            className={mergeClasses(
              'flex h-8 w-8 items-center justify-center',
              {
                'text-primary': type === 'info',
                'text-warning': type === 'warning',
                'text-danger': type === 'error',
                'text-success': type === 'success',
              }
            )}
          >
            {messageIcon}
          </span>
        )}
        <div className="flex-1">
          {title && (
            <Trans parent="p" className="font-bold">
              {title}
            </Trans>
          )}
          <Trans parent="p">{description}</Trans>
        </div>
        {onClose && (
          <Button
            onClick={onClose}
            variant="none"
            icon={<IconCross className="h-6 w-6" />}
          />
        )}
      </div>
    </div>
  )
}
