import { AnimatePresence, motion } from 'framer-motion'
import {
  useFeedbackAlertContextDispatch,
  useFeedbackAlertContextState,
} from 'modules/common/contexts/FeedbackAlertContext'
import { useEffect, useState } from 'react'
import { ToastMessage } from '../ToastMessage/ToastMessage'

const AUTO_DISMISS_MS = 5000
const BOTTOM_OFFSET_PX = '28px'

const VARIANTS = {
  invisible: {
    y: `calc(100% + ${BOTTOM_OFFSET_PX})`,
  },
  visible: {
    y: '0px',
  },
}

const TRANSITION = { duration: 0.8, easings: 'easeInOut' }

export const FeedbackAlert = () => {
  const { hideAlert } = useFeedbackAlertContextDispatch()
  const alert = useFeedbackAlertContextState()
  const [toastKey, setToastKey] = useState(0)

  useEffect(() => {
    if (alert) {
      setToastKey((key) => key + 1)
      const interval = setTimeout(hideAlert, AUTO_DISMISS_MS)

      return () => clearInterval(interval)
    }
  }, [alert, hideAlert])

  return (
    <AnimatePresence>
      {alert && (
        <motion.div
          className="fixed inset-x-0 bottom-7 z-20 flex w-full justify-center"
          variants={VARIANTS}
          transition={TRANSITION}
          initial="invisible"
          animate="visible"
          exit="invisible"
          data-testid={`${alert.type}-feedback-alert`}
        >
          <ToastMessage
            key={toastKey}
            description={alert.description}
            type={alert.type}
            title={alert.title}
            onClose={hideAlert}
          />
        </motion.div>
      )}
    </AnimatePresence>
  )
}
